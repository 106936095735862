<template>
  <div class="py-3 weather">
    <b-row>
      <b-col cols="12" class="d-flex">
        <div
          v-for="(weatherState, index) in weatherStates"
          :key="index"
          class="weather-item"
        >
          <div
            class="weather-state cursor-pointer mx-1 my-1 d-flex align-items-center justify-content-center"
            @click="weatherParam(weatherState)"
            :class="{ selected: weatherState.selected }"
          >
            <font-awesome-icon size="2x" :icon="weatherState.icon" />
          </div>
        </div>
      </b-col>
      <b-col cols="12" class="d-flex temperature">
        <div
          v-for="(temp, index) in temperatures"
          :key="index"
          class="temperature-item mx-1 my-1"
        >
          <div
            class="temp-item cursor-pointer d-flex flex-column justify-content-around h-100"
            @click="weatherParam(temp)"
            :class="{ selected: temp.selected }"
          >
            <b-icon scale="1.1" class="ico" icon="check-circle-fill"></b-icon>
            <div class="celsius text-right">{{ temp.temp }}°</div>
            <div>
              <div class="bar mb-1" :style="{ background: temp.color }"></div>
              <div
                class="label"
                :class="temp.labelClass"
                :style="{ color: temp.color }"
              >
                {{ temp.tempLabel }}
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    currentWeather: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      pow: 0,
      weatherStates: [
        {
          selected: false,
          icon: 'sun',
          value: 0,
        },
        {
          selected: false,
          icon: 'cloud',
          value: 1,
        },
        {
          selected: false,
          icon: 'wind',
          value: 2,
        },
        {
          selected: false,
          icon: 'cloud-showers-heavy',
          value: 3,
        },
        {
          selected: false,
          icon: 'snowflake',
          value: 4,
        },
      ],
      temperatures: [
        {
          temp: '< 0',
          tempLabel: this.$t('planification.icy'),
          color: '#9DEBF5',
          selected: false,
          value: 5,
        },
        {
          temp: '0-15',
          tempLabel: this.$t('planification.cold'),
          color: '#57B5DD',
          selected: false,
          value: 6,
        },
        {
          temp: '15-25',
          tempLabel: this.$t('planification.regular'),
          color: '#F6BC1C',
          selected: false,
          value: 7,
        },
        {
          temp: '> 25',
          tempLabel: this.$t('planification.warm'),
          color: '#F05230',
          selected: false,
          value: 8,
        },
      ],
    }
  },
  watch: {
    pow(val) {
      this.$emit('weatherParamsChange', val)
    },
  },
  methods: {
    weatherParam(item) {
      if (item.selected) {
        item.selected = false
        this.pow -= Math.pow(2, item.value)
      } else {
        item.selected = true
        this.pow += Math.pow(2, item.value)
      }
    },
  },
  created() {
    if (this.currentWeather >= 0) {
      this.pow = this.currentWeather
      for (let i = 0; i < 9; i++) {
        if ((this.pow & (1 << i)) !== 0) {
          if (i <= 4) this.weatherStates[i].selected = true
          else this.temperatures[i - 5].selected = true
        }
      }
    } else {
      this.pow = 511
      for (let i = 0; i < 9; i++) {
        if ((this.pow & (1 << i)) !== 0) {
          if (i <= 4) this.weatherStates[i].selected = true
          else this.temperatures[i - 5].selected = true
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.weather-item {
  width: 20%;
}
.weather-state {
  border-radius: 3px;
  height: 60px;
  background: #cccccc;
  color: white;
  &:hover {
    background: #a8a8a8;
  }
  &.selected {
    background: $primary;
  }
}
.temperature {
  width: 100%;
  .temperature-item {
    width: 25%;
  }
  .temp-item {
    border-radius: 3px;
    padding: 10px 4px;
    position: relative;
    border: 1px transparent solid;
    .ico {
      display: none;
      position: absolute;
      top: 5px;
      left: 5px;
      color: $primary;
    }
    &:hover {
      background: #f2f2f2;
    }
    &.selected {
      border: 1px $primary solid;
      background: #f2f2f2;
      .ico {
        display: block;
      }
    }
  }
  .celsius {
    margin-bottom: 20px;
    color: #6a6a6a;
    font-weight: bold;
  }
  .bar {
    width: 100%;
    height: 8px;
  }
  .label {
    font-weight: bold;
    font-size: 0.75rem;
    text-transform: uppercase;
  }
}
</style>
